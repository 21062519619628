<template>
  <component :is="name" class="__icon"></component>
</template>

<script>
import Menu from "./Menu.vue";
import Profile from "./Profile.vue";
import Wallet from "./Wallet.vue";
import Home from "./Home.vue";
import Work from "./Work.vue";
import Category from "./Category.vue";
import Down from "./Down.vue";
import Group from "./Group.vue";
import Channel from "./Channel.vue";
import Send from "./Send.vue";
import Game from "./Game.vue";
import Users from "./Users.vue";
import Chart from "./Chart.vue";
import Discount from "./Discount.vue";
import Document from "./Document.vue";
import Calling from "./Calling.vue";
import Info from "./Info.vue";

export default {
  components: {
    Menu,
    Profile,
    Wallet,
    Home,
    Work,
    Category,
    Down,
    Group,
    Channel,
    Send,
    Game,
    Users,
    Chart,
    Discount,
    Document,
    Calling,
    Info,
  },
  props: ["name"],
};
</script>

<style lang="scss">
.__icon {
  display: inline-block;
  fill: currentColor;
  height: 1.8em;
  max-height: 100%;
  margin-inline-end: 0.5em;
}
</style>
