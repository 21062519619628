<template>
  <header class="main-header bg-coolGray-50 h-14 border-b border-coolGray-200">
    <div class="2xl:container h-full mx-auto">
      <div
        class="h-full p-2 float-right cursor-pointer lg:hidden"
        @click="$store.commit('toggleMenu')"
      >
        <Icon name="Menu" class="text-blueGray-500 hover:text-blueGray-700 h-full"></Icon>
      </div>
      <div class="h-full float-left cursor-pointer">
        <router-link to="/" class="block p-2 h-full">
          <Logo class="h-full" />
        </router-link>
      </div>
      <div class="h-full float-left cursor-pointer" dir="ltr">
        <router-link to="/" class="block p-2 h-full">
          <h1 class="text-lg leading-5">telisha.ir</h1>
          <p class="texs-sm leading-4">mnrtech</p>
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
import Icon from "@/components/icon/Icon.vue";
import Logo from "@/components/Logo.vue";

export default {
  components: {
    Icon,
    Logo,
  },
};
</script>

<style>
.main-header {
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  /* position: fixed; */
}
</style>
