<template>
  <div class="text-center flex items-center justify-center flex-col h-screen">
    <Logo class="h-72" />
    <div><p>loading ...</p></div>
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
export default {
  components: { Logo },
};
</script>
