<template>
  <div class="flex flex-col h-screen">
    <Header />
    <main
      class="
        main-body
        2xl:container
        mx-auto
        border-l border-r border-coolGray-200
        w-full
        h-full
        flex
      "
      v-if="loadRouter"
    >
      <SideMenu />
      <div
        class="main-container scroll-style flex-1 text-blueGray-700"
        id="mainContainer"
      >
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
import Header from "@/components/main/Header.vue";
import SideMenu from "@/components/main/SideMenu.vue";

export default {
  components: {
    Header,
    SideMenu,
  },
  data() {
    return {
      loadRouter: false,
    };
  },
  mounted() {
    let $this = this;
    this.$router
      .isReady()
      .then(function () {
        if (
          ($this.$store.state.user?.admin?.is_admin ?? false) == false &&
          $this.$route?.meta?.admin
        ) {
          $this.$store.commit("setStatus", 404);
        }
        $this.loadRouter = true;
      })
      .catch();
  },
};
</script>

<style>
.main-body {
  overflow: hidden;
}
.main-container {
  /* height: calc(100vh - 3.5rem); */
  /* height: 100%; */
  /* padding-bottom: 1px; */
  overflow: auto;
  scroll-behavior: smooth;
}
</style>
