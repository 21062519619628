import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    menuState: false,

    user: false,
    status: 200,

    firstCreate: false,

    lastAxiosError: false,
  },
  mutations: {
    opneMenu(state) {
      state.menuState = true;
    },
    closeMenu(state) {
      state.menuState = false;
    },
    toggleMenu(state) {
      state.menuState = !state.menuState;
    },
    lastError(state, error) {
      state.lastAxiosError = error;
    },

    userLogged(state, user) {
      if (user && localStorage.getItem("token")) {
        axios.defaults.headers["Authorization"] =
          "Bearer " + localStorage.getItem("token");
      } else {
        axios.defaults.headers["Authorization"] = null;
      }
      state.user = user;
    },
    setStatus(state, status) {
      state.status = status;
    },
    setCreate(state, status) {
      state.firstCreate = status;
    },
  },
  actions: {},
  modules: {},
});
