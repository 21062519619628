<template>
  <div
    class="menu-back xl:hidden"
    v-show="$store.state.menuState"
    @click="$store.commit('closeMenu')"
  ></div>
  <div
    class="
      scroll-style
      side-menu
      bg-coolGray-50
      text-blueGray-500
      border-l border-blueGray-200
      pb-20
    "
    :class="{ _open: $store.state.menuState }"
  >
    <div class="p-3">
      <div class="menu-top-box bg-coolGray-100 border border-coolGray-200 rounded-md">
        <router-link
          :to="{ name: 'Profile', params: { id: this.$store.state.user?.admin?.id } }"
          class="block p-2 hover:text-blueGray-700"
          v-if="
            $store.state?.user?.admin &&
            $store.state?.user?.admin?.id != $store.state?.user?.id
          "
        >
          <Icon name="Profile"></Icon>
          {{
            [$store.state.user.admin.first_name, $store.state.user.admin.last_name]
              .join(" ")
              .trim()
          }}
        </router-link>
        <router-link
          to="/login"
          class="block p-2 hover:text-blueGray-700"
          v-if="$store.state.user === false"
        >
          <Icon name="Profile"></Icon>
          ورود \ ثبت نام
        </router-link>
        <router-link
          :to="{ name: 'Profile', params: { id: this.$store.state.user?.id } }"
          class="block p-2 hover:text-blueGray-700"
          v-else
        >
          <Icon name="Profile"></Icon>
          {{
            [$store.state.user.first_name, $store.state.user.last_name].join(" ").trim()
          }}
        </router-link>

        <router-link
          :to="{ name: 'Wallet', params: { id: $store.state.user?.id } }"
          class="block p-2 hover:text-blueGray-700"
          v-if="$store.state.user !== false"
        >
          <Icon name="Wallet"></Icon>
          موجودی:
          {{
            $number.format(
              $store.state.user.wallet +
                $store.state.user.earn +
                $store.state.user.bonus -
                $store.state.user.debit
            )
          }}
        </router-link>
      </div>
    </div>
    <!-- ANCHOR - user panel -->
    <div v-if="$store.state.user !== false">
      <MenuLink :to="{ name: 'Panel', params: { id: this.$store.state.user?.id } }">
        <Icon name="Category"></Icon>
        داشبورد
      </MenuLink>
      <MenuLink
        :to="{ name: 'Wallet', params: { id: this.$store.state.user?.id } }"
        :list="menu.wallet"
        rootName="wallet"
      >
        <Icon name="Wallet"></Icon>
        کیف پول
      </MenuLink>
      <MenuLink
        :to="{ name: 'Ad', params: { id: this.$store.state.user?.id } }"
        :list="menu.ad"
        rootName="ad"
      >
        <Icon name="Send"></Icon>
        تبلیغات
      </MenuLink>
      <MenuLink
        :to="{ name: 'GroupOwner', params: { id: this.$store.state.user?.id } }"
        :list="menu.group"
        rootName="group"
      >
        <Icon name="Group"></Icon>
        گروه ها
      </MenuLink>
      <MenuLink
        :to="{ name: 'ChannelOwner', params: { id: this.$store.state.user?.id } }"
        :list="menu.channel"
        rootName="channel"
      >
        <Icon name="Channel"></Icon>
        کانال ها
      </MenuLink>
      <hr class="border-coolGray-200" />
    </div>
    <!-- ANCHOR - admin panel -->
    <div v-if="$store.state.user?.admin">
      <b class="block py-2 px-5">پنل مدیریت</b>
      <MenuLink :to="{ name: 'Admin' }">
        <Icon name="Category"></Icon>
        داشبورد
      </MenuLink>
      <MenuLink :to="{ name: 'AdminBots' }" rootName="adminBot">
        <Icon name="Game"></Icon>
        ربات ها
      </MenuLink>
      <MenuLink :to="{ name: 'AdminUsers' }">
        <Icon name="Users"></Icon>
        کاربران
      </MenuLink>
      <MenuLink :to="{ name: 'AdminGroups' }">
        <Icon name="Group"></Icon>
        گروه ها
      </MenuLink>
      <MenuLink :to="{ name: 'AdminChannels' }">
        <Icon name="Channel"></Icon>
        کانال ها
      </MenuLink>
      <MenuLink :to="{ name: 'AdminAds' }">
        <Icon name="Send"></Icon>
        تبلیغات
      </MenuLink>
      <MenuLink
        :to="{ name: 'AdminIncome' }"
        :list="menu.admin.income"
        rootName="AdminIncome"
      >
        <Icon name="Chart"></Icon>
        امور مالی
      </MenuLink>
      <hr class="border-coolGray-200" />
    </div>
    <!-- ANCHOR - main menu -->
    <MenuLink to="/">
      <Icon name="Home"></Icon>
      صفه اصلی
    </MenuLink>
    <MenuLink to="/tariffs">
      <Icon name="Discount"></Icon>
      تعرفه ها
    </MenuLink>
    <MenuLink to="/common-questions">
      <Icon name="Info"></Icon>
      سوالات متداول
    </MenuLink>
    <MenuLink to="/rules">
      <Icon name="Document"></Icon>
      قوانین و مرام نامه
    </MenuLink>
    <!-- <MenuLink to="/">
      <Icon name="Document"></Icon>
      وبلاگ
    </MenuLink> -->
    <MenuLink to="/contact">
      <Icon name="Calling"></Icon>
      تماس با ما
    </MenuLink>
  </div>
</template>

<script>
import Icon from "@/components/icon/Icon.vue";
import MenuLink from "@/components/main/MenuLink.vue";

export default {
  components: {
    Icon,
    MenuLink,
  },
  data() {
    return {};
  },
  computed: {
    menu() {
      return {
        wallet: [
          {
            text: "موجودی",
            link: { name: "Wallet", params: { id: this.$store.state.user?.id } },
          },
          {
            text: "شارژ کیف پول",
            link: { name: "WalletCharge", params: { id: this.$store.state.user?.id } },
          },
          {
            text: "تسویه حساب",
            link: { name: "Checkout", params: { id: this.$store.state.user?.id } },
          },
        ],
        group: [
          {
            text: "گروه های من (مدیر)",
            link: {
              name: "GroupOwner",
              params: { id: this.$store.state.user?.id },
            },
          },
          {
            text: "سایر گروه ها (عضو)",
            link: { name: "GroupUser", params: { id: this.$store.state.user?.id } },
          },
        ],
        ad: [
          {
            text: "سفارش های من",
            link: { name: "Ad", params: { id: this.$store.state.user?.id } },
          },
          {
            text: "سفارش تبلیغات سراسری",
            link: { name: "GroupOrder", params: { id: this.$store.state.user?.id } },
          },
          {
            text: "سفارش تبلیغات هدفمند",
            link: { name: "TargetOrder", params: { id: this.$store.state.user?.id } },
          },
        ],
        channel: [
          {
            text: "کانال های من (مدیر)",
            link: { name: "ChannelOwner", params: { id: this.$store.state.user?.id } },
          },
          {
            text: "سایر کانال‌ها (عضو)",
            link: { name: "ChannelUser", params: { id: this.$store.state.user?.id } },
          },
        ],
        admin: {
          income: [
            {
              text: "حساب ماهانه",
              link: { name: "AdminIncome" },
            },
            {
              text: "پرداخت ها",
              link: { name: "AdminPayments" },
            },
          ],
        },
      };
    },
  },
};
</script>

<style lang="scss">
.menu-back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @apply bg-coolGray-300;
  opacity: 0.5;
  z-index: 10;
}
.side-menu {
  width: 320px;
  max-width: 80%;
  height: 100%;
  z-index: 20;
  overflow: auto;

  @media (max-width: 1023px) {
    position: fixed;
    right: -320px;
    &._open {
      right: 0;
    }
  }

  .menu-link {
    &.router-link-active,
    &.child-active {
      box-shadow: -6px 0px 0px 0px #0ea5e9 inset;
    }
  }
  // .menu-top-box,
  // .menu-link {
  //   svg {
  //     margin-inline-end: 0.5em;
  //   }
  // }
  .menu-link-sub::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-inline-end: 10px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    @apply bg-coolGray-300;
  }
  .router-link-active.menu-link-sub::before {
    background-color: #0ea5e9;
  }
  .menu-list {
    &._open-lisst {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
