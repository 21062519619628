<template>
  <component
    :is="is"
    :to="to"
    :href="href"
    class="__btn rounded-md truncate transition-colors"
    :class="[
      list_color[color][light ? 'light' : 'dark'],
      { 'btn-disabled': disabled, 'btn-loading': loading },
    ]"
    :type="is == 'button' ? type || 'button' : null"
    @click="onClick"
    :disabled="is == 'button' ? disabled : null"
  >
    <MnrSpinner v-if="loading" />
    <slot v-else />
  </component>
</template>

<script>
import MnrSpinner from "./mnr/MnrSpinner.vue";

export default {
  components: {
    MnrSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "sky",
    },
    light: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object, undefined],
      default: undefined,
    },
    href: {
      type: [String, undefined],
      default: undefined,
    },
    type: {
      type: [String, Object, undefined],
      default: undefined,
    },
  },
  emits: ["click"],
  data() {
    return {
      list_color: {
        blueGray: {
          light: [
            "bg-blueGray-100",
            "hover:bg-blueGray-200",
            "active:bg-blueGray-300",
            "text-blueGray-800",
          ],
          dark: [
            "bg-blueGray-500",
            "hover:bg-blueGray-600",
            "active:bg-blueGray-700",
            "text-blueGray-50",
          ],
        },
        sky: {
          light: ["bg-sky-100", "hover:bg-sky-200", "active:bg-sky-300", "text-sky-800"],
          dark: ["bg-sky-500", "hover:bg-sky-600", "active:bg-sky-700", "text-sky-50"],
        },
        cyan: {
          light: [
            "bg-cyan-100",
            "hover:bg-cyan-200",
            "active:bg-cyan-300",
            "text-cyan-800",
          ],
          dark: [
            "bg-cyan-500",
            "hover:bg-cyan-600",
            "active:bg-cyan-700",
            "text-cyan-50",
          ],
        },
        amber: {
          light: [
            "bg-amber-100",
            "hover:bg-amber-200",
            "active:bg-amber-300",
            "text-amber-800",
          ],
          dark: [
            "bg-amber-500",
            "hover:bg-amber-600",
            "active:bg-amber-700",
            "text-amber-50",
          ],
        },
        red: {
          light: ["bg-red-100", "hover:bg-red-200", "active:bg-red-300", "text-red-800"],
          dark: ["bg-red-500", "hover:bg-red-600", "active:bg-red-700", "text-red-50"],
        },
        green: {
          light: [
            "bg-green-100",
            "hover:bg-green-200",
            "active:bg-green-300",
            "text-green-800",
          ],
          dark: [
            "bg-green-500",
            "hover:bg-green-600",
            "active:bg-green-700",
            "text-green-50",
          ],
        },
        teal: {
          light: [
            "bg-teal-100",
            "hover:bg-teal-200",
            "active:bg-teal-300",
            "text-teal-800",
          ],
          dark: [
            "bg-teal-500",
            "hover:bg-teal-600",
            "active:bg-teal-700",
            "text-teal-50",
          ],
        },
      },
    };
  },
  computed: {
    // colorClass() {
    //   if (this.light) {
    //     return [
    //       "bg-" + this.color + "-100",
    //       "hover:bg-" + this.color + "-200",
    //       "active:bg-" + this.color + "-300",
    //       "text-" + this.color + "-800",
    //     ];
    //   } else {
    //     return [
    //       "bg-" + this.color + "-500",
    //       "hover:bg-" + this.color + "-600",
    //       "active:bg-" + this.color + "-700",
    //       "text-" + this.color + "-50",
    //     ];
    //   }
    // },
    is() {
      if (this.to) return "router-link";
      else if (this.href) return "a";
      else return "button";
    },
  },
  methods: {
    onClick() {
      if (!(this.loading || this.disabled)) {
        this.$emit("click");
      }
    },
  },
};
</script>
