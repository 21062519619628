<template>
  <div class="home">
    <header>
      <div class="flex flex-wrap sm:flex-nowrap sm:gap-5 p-5 items-center">
        <div class="w-full sm:w-6/12 2xl:px-32 2xl:py-32">
          <div class="flex items-center">
            <Logo class="h-24" />
            <h2 class="text-5xl pr-5">تلیشا</h2>
          </div>
          <p class="pt-10">خدمات و تبلیغات در تلگرام</p>
          <p class="pt-10">با تلیشا دیگه خیالت راحته</p>
        </div>
        <div class="w-full sm:w-6/12">
          <img
            class="w-full"
            src="@/assets/large-images/home_1.svg"
            alt="تلیشا، خدمات و تبلیغات گسترده در تلگرام"
          />
        </div>
      </div>
    </header>
    <div class="mt-20">
      <div
        class="flex flex-wrap sm:flex-nowrap sm:gap-5 p-5 items-center flex-row-reverse"
      >
        <div class="w-full sm:w-6/12 2xl:px-20 2xl:py-20">
          <h2 class="text-2xl">تبلیغات سراسری و هدفمند در تلگرام</h2>
          <p class="pt-10">
            اگر به دنبال تبلیغات مطمئن در تلگرام هستی میتونی تبلیغات خودت رو در تلیشا ثبت
            کنی
          </p>
          <Button :to="{ name: 'CommonQuestions' }" class="mt-5" color="teal">
            سوالات متداول در مورد تبلیغات
          </Button>
          <Button
            href="https://t.me/sefareshtabligh_bot"
            target="_blank"
            class="mt-3"
            color="sky"
          >
            ربات سفارش تبلیغ در تلگرام
          </Button>
        </div>
        <div class="w-full sm:w-6/12">
          <img
            class="w-full"
            src="@/assets/large-images/Business_Plan_pana.svg"
            alt="تلیشا، خدمات و تبلیغات گسترده در تلگرام"
          />
        </div>
      </div>
    </div>
    <div class="mt-20">
      <div class="flex flex-wrap sm:flex-nowrap sm:gap-5 p-5 items-center">
        <div class="w-full sm:w-6/12 2xl:px-20 2xl:py-20">
          <h2 class="text-2xl">ربات مدیریت گروه تلگرام</h2>
          <p class="pt-10">
            ربات مدیریت گروه قدرتمند با قابلیت های باور نکردنی و بیش از 5 سال تجربه
          </p>
          <Button
            :to="{ name: 'CommonQuestions', hash: '#group_bot' }"
            class="mt-5"
            color="teal"
          >
            سوالات متداول در مورد ربات گروه
          </Button>
          <Button href="https://t.me/mnrG1bot" target="_blank" class="mt-3" color="sky">
            ربات مدیر گروه در تلگرام
          </Button>
        </div>
        <div class="w-full sm:w-6/12">
          <img
            class="w-full"
            src="@/assets/large-images/Chat_bot_pana.svg"
            alt="تلیشا، خدمات و تبلیغات گسترده در تلگرام"
          />
        </div>
      </div>
    </div>
    <div class="mt-20">
      <div
        class="flex flex-wrap sm:flex-nowrap sm:gap-5 p-5 items-center flex-row-reverse"
      >
        <div class="w-full sm:w-6/12 2xl:px-20 2xl:py-20">
          <h2 class="text-2xl">ربات مدیریت کانال تلگرام</h2>
          <p class="pt-10">
            ربات مدیریت کانال تلگرام با قابلیت های کاربردی مانند کلید شیشه‌ای
          </p>

          <Button
            :to="{ name: 'CommonQuestions', hash: '#channel_bot' }"
            class="mt-5"
            color="teal"
          >
            سوالات متداول در مورد ربات کانال
          </Button>
          <Button href="https://t.me/mnrcbot" target="_blank" class="mt-3" color="sky">
            ربات مدیر کانال در تلگرام
          </Button>
        </div>
        <div class="w-full sm:w-6/12">
          <img
            class="w-full"
            src="@/assets/large-images/Chat_bot_amico.svg"
            alt="تلیشا، خدمات و تبلیغات گسترده در تلگرام"
          />
        </div>
      </div>
    </div>
    <!-- <div class="mt-20 bg-blueGray-200 border-t border-blueGray-300 p-5 flex">
      <div class="">
        <a
          class="bg-white border border-blueGray-300 rounded-md p-3 block"
          referrerpolicy="origin"
          target="_blank"
          href="https://trustseal.enamad.ir/?id=246755&amp;Code=iUfY7qvFXdD5zZ3BIj3v"
        >
          <img src="@/assets/star0.png" />
        </a>
      </div>
    </div> -->
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
import Button from "@/components/Button.vue";

// @ is an alias to /src

export default {
  name: "Home",
  components: { Logo, Button },
};
</script>
