<template>
  <div class="flex">
    <router-link
      :to="to"
      class="flex-1 menu-link block py-2 px-5 hover:text-blueGray-700 hover:bg-coolGray-200"
      :class="{ 'child-active': activeChild }"
      @click="firstTime = openList ? false : true"
    >
      <slot />
    </router-link>
    <div
      class="menu-list w-10 p-2 cursor-pointer hover:bg-coolGray-200 hover:text-blueGray-700"
      :class="{ '_open-lisst': openList || (firstTime && activeChild) }"
      @click="
        openList = firstTime && activeChild ? openList : !openList;
        firstTime = false;
      "
      v-if="list.length"
    >
      <Icon name="Down" class="w-full"></Icon>
    </div>
  </div>
  <div class="bg-coolGray-100" v-if="list.length" v-show="openList || (firstTime && activeChild)">
    <router-link
      v-for="(item, index) in list"
      :key="index"
      :to="item.link"
      class="menu-link-sub block py-3 px-5 hover:bg-blueGray-200 hover:text-blueGray-700"
    >
      {{ item.text }}
    </router-link>
  </div>
</template>

<script>
import Icon from "@/components/icon/Icon.vue";

export default {
  components: {
    Icon,
  },
  props: {
    to: null,
    list: {
      default: [],
    },
    rootName: {
      type: String,
    },
  },
  data() {
    return {
      openList: false,
      firstTime: true,
    };
  },
  computed: {
    activeChild() {
      return this.$route.meta.rootName && this.rootName
        ? this.$route.meta.rootName == this.rootName
        : false;
    },
  },
};
</script>
