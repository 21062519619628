<template>
  <div class="text-center flex items-center justify-center flex-col h-screen">
    <Logo class="h-40" />
    <div class="mt-5">
      <div class="text-7xl text-red-600 font-bold">{{ code }}</div>
      <div class="text-lg text-red-600 font-bold">متاسفانه خطا رخ داد</div>
    </div>
    <div class="mt-5">
      <router-link to="/" class="text-sky-500">رفتن صفحه اصلی سایت</router-link>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
export default {
  components: { Logo },
  computed: {
    code() {
      let code = this.$store.state.status;
      if (code == 200 && this.$route?.meta?.error) {
        code = 404;
      }
      return code;
    },
  },
  mounted: async function () {
    try {
      const response = await this.$axios.post("/api/front-error", {
        url: this.$route?.fullPath,
        user: this.$store.state.user,
        errorRoute: this.$route?.meta?.error,
        status: this.$store.state.status,
        axios: this.$store.state.lastAxiosError,
      });
      // console.log(response);
    } catch (error) {
      // console.error(error);
    }
  },
};
</script>
