<template>
  <notifications
    classes="my-notification"
    class="mt-16"
    position="top right"
    :duration="1500"
    :max="3"
  />
  <Loading v-if="!$store.state.firstCreate" />
  <ErrorLayout
    v-else-if="[404].find((x) => x == $store.state.status) || $route?.meta?.error"
  />
  <component v-else :is="layout" dir="rtl">
    <router-view />
  </component>
</template>

<script>
// import MainLayout from "@/layouts/MainLayout.vue";
import PanelLayout from "@/layouts/PanelLayout.vue";
import ErrorLayout from "./layouts/ErrorLayout.vue";
import Loading from "./layouts/Loading.vue";
// import Empty from "@/layouts/Empty.vue";

export default {
  components: {
    // Empty,
    PanelLayout,
    ErrorLayout,
    Loading,
  },
  computed: {
    layout() {
      return this.$route.meta.layout ?? "PanelLayout";
    },
  },
  created() {
    let $this = this;
    this.$router
      .isReady()
      .then(function () {
        $this.$axios
          .get("/api/getMe", {
            params: {
              id: $this.$route.params.id,
            },
          })
          .then(function (response) {
            // console.log("aaaaaaaaaaaa", response);
            // if (response?.data?.auth) {
            //   store.commit("userLogged", response.data.auth);
            // }
          })
          .catch(function () {})
          .finally(function () {
            $this.$store.commit("setCreate", true);
          });
      })
      .catch();
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: vazir;
  src: url("~@/assets/Vazir-Light-FD-WOL.woff") format("woff");
  font-weight: normal;
}
@font-face {
  font-family: vazir;
  src: url("~@/assets/Vazir-Medium-FD-WOL.woff") format("woff");
  font-weight: bold;
}
#app {
  font-family: vazir, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // line-height: 2em;
  // p {
  //   line-height: 2em;
  // }
}
.scroll-style {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }
  &::-webkit-scrollbar-thumb {
    border: solid 2px #f0f0f0;
    background: #b6b6b6;
  }
  &::-webkit-scrollbar-thumb:hover {
    border: none;
    background: #646464;
  }
}
html {
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
}
</style>
