import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import store from "@/store";
import axios from "axios";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "PanelLayout",
    },
  },
  {
    path: "/tariffs",
    name: "Tariffs",
    component: () => import("../views/Tariffs.vue"),
  },
  {
    path: "/rules",
    name: "Rules",
    component: () => import("../views/Rules.vue"),
  },
  {
    path: "/common-questions",
    name: "CommonQuestions",
    component: () => import("../views/CommonQuestions.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  /**
   * ANCHOR - panel
   * main panel routes
   */
  {
    path: "/panel/:id(\\d+)?",
    name: "Panel",
    component: () => import("../views/panel/Index.vue"),
  },
  {
    path: "/panel/:id(\\d+)?/im-not-robot",
    name: "ImNotBot",
    component: () => import("../views/panel/ImNotBot.vue"),
  },
  {
    path: "/panel/profile/:id(\\d+)?",
    name: "Profile",
    component: () => import("../views/panel/Profile.vue"),
  },
  /**
   * ANCHOR - wallet
   * wallet routes
   */
  {
    path: "/panel/:id(\\d+)/wallet",
    component: () => import("../views/panel/wallet/Index.vue"),
    name: "Wallet",
    meta: {
      rootName: "wallet",
    },
  },
  {
    path: "/panel/:id(\\d+)/wallet/charge",
    component: () => import("../views/panel/wallet/Charge.vue"),
    name: "WalletCharge",
    meta: {
      rootName: "wallet",
    },
  },
  {
    path: "/panel/:id(\\d+)/wallet/charge/pay-result/:payId(\\d+)",
    component: () => import("../views/panel/wallet/ChargePayRes.vue"),
    name: "WalletChargePayRes",
    meta: {
      rootName: "wallet",
    },
  },
  {
    path: "/panel/:id(\\d+)/wallet/charge/check-and-pay/:payId(\\d+)",
    component: () => import("../views/panel/wallet/ChargeCheck.vue"),
    name: "WalletChargeCheck",
    meta: {
      rootName: "wallet",
    },
  },
  {
    path: "/panel/:id(\\d+)/wallet/checkout",
    component: () => import("../views/panel/wallet/Checkout.vue"),
    name: "Checkout",
    meta: {
      rootName: "wallet",
    },
  },
  {
    path: "/panel/:id(\\d+)/fail-pay/:payId(\\d+)",
    component: () => import("../views/panel/wallet/FailPay.vue"),
    name: "FailPay",
    meta: {
      rootName: "wallet",
    },
  },
  /**
   * ANCHOR - ads
   * ads routes
   */
  {
    path: "/panel/:id(\\d+)/ad",
    component: () => import("../views/panel/ad/Index.vue"),
    name: "Ad",
    meta: {
      rootName: "ad",
    },
  },
  /**
   * ANCHOR - ads group
   * ads group routes
   */
  {
    path: "/panel/:id(\\d+)/ad/group",
    component: () => import("../views/panel/ad/GroupOrder.vue"),
    name: "GroupOrder",
    meta: {
      rootName: "ad",
    },
  },
  {
    path: "/panel/:id(\\d+)/ad/group/:adId",
    name: "GroupOrderInfo",
    component: () => import("../views/panel/ad/GroupOrderInfo.vue"),
    meta: {
      rootName: "ad",
    },
  },
  {
    path: "/panel/:id(\\d+)/ad/group/:adId/edit",
    name: "GroupOrderEdit",
    component: () => import("../views/panel/ad/GroupOrderEdit.vue"),
    meta: {
      rootName: "ad",
    },
  },
  {
    path: "/panel/:id(\\d+)/ad/group/:payId(\\d+)/check-and-pay",
    name: "GroupOrderCheck",
    component: () => import("../views/panel/ad/GroupOrderCheck.vue"),
    meta: {
      rootName: "ad",
    },
  },
  {
    path: "/panel/:id(\\d+)/ad/group/:payId(\\d+)/pay-result",
    name: "GroupOrderPayRes",
    component: () => import("../views/panel/ad/GroupOrderPayRes.vue"),
    meta: {
      rootName: "ad",
    },
  },
  /**
   * ANCHOR - ads target
   * ads target routes
   */
  {
    path: "/panel/:id(\\d+)/ad/target",
    component: () => import("../views/panel/ad/TargetOrder.vue"),
    name: "TargetOrder",
    meta: {
      rootName: "ad",
    },
  },
  {
    path: "/panel/:id(\\d+)/ad/target/:adId",
    name: "TargetOrderInfo",
    component: () => import("../views/panel/ad/TargetOrderInfo.vue"),
    meta: {
      rootName: "ad",
    },
  },
  {
    path: "/panel/:id(\\d+)/ad/target/:adId/edit",
    name: "TargetOrderEdit",
    component: () => import("../views/panel/ad/TargetOrderEdit.vue"),
    meta: {
      rootName: "ad",
    },
  },
  {
    path: "/panel/:id(\\d+)/ad/target/:adId(\\d+)/groups",
    name: "TargetOrderSelect",
    component: () => import("../views/panel/ad/TargetOrderGroup.vue"),
    meta: {
      rootName: "ad",
    },
  },
  {
    path: "/panel/:id(\\d+)/ad/target/:adId(\\d+)/group/:groupId(\\d+)",
    name: "TargetOrderInGroup",
    component: () => import("../views/panel/ad/TargetOrderInGroup.vue"),
    meta: {
      rootName: "ad",
    },
  },
  {
    path: "/panel/:id(\\d+)/ad/target/:adId/selected-group",
    name: "TargetOrderSelected",
    component: () => import("../views/panel/ad/TargetOrderSelected.vue"),
    meta: {
      rootName: "ad",
    },
  },
  {
    path: "/panel/:id(\\d+)/ad/target/:payId(\\d+)/check-and-pay",
    name: "TargetOrderCheck",
    component: () => import("../views/panel/ad/TargetOrderCheck.vue"),
    meta: {
      rootName: "ad",
    },
  },
  {
    path: "/panel/:id(\\d+)/ad/target/:payId(\\d+)/pay-result",
    name: "TargetOrderPayRes",
    component: () => import("../views/panel/ad/TargetOrderPayRes.vue"),
    meta: {
      rootName: "ad",
    },
  },
  /**
   * ANCHOR - group owner routes
   */
  {
    path: "/panel/:id(\\d+)/group-owner/",
    name: "GroupOwner",
    component: () => import("../views/panel/group/GroupOwner.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId",
    name: "GroupOwnerSetting",
    component: () => import("../views/panel/group/GroupOwnerSetting.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId(\\d+)/setting/:settingId(\\d+)",
    name: "GroupOwnerSettingUser",
    component: () => import("../views/panel/group/GroupOwnerSettingUser.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/setting",
    name: "GroupOwnerSettingMain",
    component: () => import("../views/panel/group/GroupOwnerSettingMain.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/bot-message",
    name: "GroupOwnerSettingBotPm",
    component: () => import("../views/panel/group/GroupOwnerSettingBotPm.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/users",
    name: "GroupOwnerSettingShowUsers",
    component: () => import("../views/panel/group/GroupOwnerSettingShowUsers.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/user/:userId",
    name: "GroupOwnerSettingShowUser",
    component: () => import("../views/panel/group/GroupOwnerSettingShowUser.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/admins",
    name: "GroupOwnerSettingShowAdmins",
    component: () => import("../views/panel/group/GroupOwnerSettingShowAdmins.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId(\\d+)/admins",
    name: "GroupOwnerSettingShowAdmins",
    component: () => import("../views/panel/group/GroupOwnerSettingShowAdmins.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId(\\d+)/repeat",
    name: "GroupOwnerSettingRepeat",
    component: () => import("../views/panel/group/GroupOwnerSettingRepeat.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/repeat/new",
    name: "GroupOwnerSettingRepeatNew",
    component: () => import("../views/panel/group/GroupOwnerSettingRepeatNew.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/repeat/:repeatId",
    name: "GroupOwnerSettingRepeatEdit",
    component: () => import("../views/panel/group/GroupOwnerSettingRepeatEdit.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/branchs",
    name: "GroupOwnerSettingBranchs",
    component: () => import("../views/panel/group/GroupOwnerSettingBranchs.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/branchs/:branchId",
    name: "GroupOwnerSettingBranchsEdit",
    component: () => import("../views/panel/group/GroupOwnerSettingBranchsEdit.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/branchs/new",
    name: "GroupOwnerSettingBranchsNew",
    component: () => import("../views/panel/group/GroupOwnerSettingBranchsNew.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/account/:accountId",
    name: "GroupOwnerSettingAccountEdit",
    component: () => import("../views/panel/group/GroupOwnerSettingAccountEdit.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/account/new",
    name: "GroupOwnerSettingAccountNew",
    component: () => import("../views/panel/group/GroupOwnerSettingAccountNew.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/bot",
    name: "GroupOwnerSettingBot",
    component: () => import("../views/panel/group/GroupOwnerSettingBot.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/vip",
    name: "GroupOwnerSettingVip",
    component: () => import("../views/panel/group/GroupOwnerSettingVip.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/income",
    name: "GroupOwnerSettingIncome",
    component: () => import("../views/panel/group/GroupOwnerSettingIncome.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/income/account",
    name: "GroupOwnerSettingIncomeAccount",
    component: () => import("../views/panel/group/GroupOwnerSettingIncomeAccount.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/income/ads",
    name: "GroupOwnerSettingIncomeAds",
    component: () => import("../views/panel/group/GroupOwnerSettingIncomeAds.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/income/to",
    name: "GroupOwnerSettingIncomeTo",
    component: () => import("../views/panel/group/GroupOwnerSettingIncomeTo.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/buy",
    name: "GroupOwnerBuy",
    component: () => import("../views/panel/group/GroupOwnerBuy.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/buy/:payId(\\d+)/check-and-pay",
    name: "GroupOwnerBuyCheck",
    component: () => import("../views/panel/group/GroupOwnerBuyCheck.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-owner/:groupId/buy/:payId(\\d+)/pay-result",
    name: "GroupOwnerBuyPayRes",
    component: () => import("../views/panel/group/GroupOwnerBuyPayRes.vue"),
    meta: {
      rootName: "group",
    },
  },

  /**
   * ANCHOR -  group user routes
   */
  {
    path: "/panel/:id(\\d+)/group-user/",
    name: "GroupUser",
    component: () => import("../views/panel/group/GroupUser.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-user/:groupId",
    name: "GroupUserInfo",
    component: () => import("../views/panel/group/GroupUserInfo.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-user/:groupId/accounts",
    name: "GroupUserAccounts",
    component: () => import("../views/panel/group/GroupUserAccounts.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-user/:groupId/accounts/:payId(\\d+)/check-and-pay",
    name: "GroupUserAccountsCheck",
    component: () => import("../views/panel/group/GroupUserAccountsCheck.vue"),
    meta: {
      rootName: "group",
    },
  },
  {
    path: "/panel/:id(\\d+)/group-user/:groupId/accounts/:payId(\\d+)/pay-result",
    name: "GroupUserAccountsPayRes",
    component: () => import("../views/panel/group/GroupUserAccountsPayRes.vue"),
    meta: {
      rootName: "group",
    },
  },
  /**
   * ANCHOR - channel route
   */
  {
    path: "/panel/:id(\\d+)/channel-owner",
    name: "ChannelOwner",
    component: () => import("../views/panel/channel/ChannelOwner.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/buy",
    name: "ChannelOwnerBuy",
    component: () => import("../views/panel/channel/ChannelOwnerBuy.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/buy/:payId(\\d+)/check-and-pay",
    name: "ChannelOwnerBuyCheck",
    component: () => import("../views/panel/channel/ChannelOwnerBuyCheck.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/buy/:payId(\\d+)/pay-result",
    name: "ChannelOwnerBuyPayRes",
    component: () => import("../views/panel/channel/ChannelOwnerBuyPayRes.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/setting",
    name: "ChannelOwnerSetting",
    component: () => import("../views/panel/channel/ChannelOwnerSetting.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/setting/main",
    name: "ChannelOwnerSettingMain",
    component: () => import("../views/panel/channel/ChannelOwnerSettingMain.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/setting/post/:postId",
    name: "ChannelOwnerSettingPost",
    component: () => import("../views/panel/channel/ChannelOwnerSettingPost.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/setting/posts",
    name: "ChannelOwnerSettingPosts",
    component: () => import("../views/panel/channel/ChannelOwnerSettingPosts.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/setting/post/:postId/info",
    name: "ChannelOwnerSettingPostInfo",
    component: () => import("../views/panel/channel/ChannelOwnerSettingPostInfo.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/setting/queues",
    name: "ChannelOwnerSettingQueues",
    component: () => import("../views/panel/channel/ChannelOwnerSettingQueues.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/setting/queue/:queueId",
    name: "ChannelOwnerSettingQueue",
    component: () => import("../views/panel/channel/ChannelOwnerSettingQueue.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/setting/queue/new",
    name: "ChannelOwnerSettingQueueNew",
    component: () => import("../views/panel/channel/ChannelOwnerSettingQueueNew.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/bot",
    name: "ChannelOwnerSettingBot",
    component: () => import("../views/panel/channel/ChannelOwnerSettingBot.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/users",
    name: "ChannelOwnerSettingShowUsers",
    component: () => import("../views/panel/channel/ChannelOwnerSettingShowUsers.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/admins",
    name: "ChannelOwnerSettingShowAdmins",
    component: () => import("../views/panel/channel/ChannelOwnerSettingShowAdmins.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/user/:userId",
    name: "ChannelOwnerSettingShowUser",
    component: () => import("../views/panel/channel/ChannelOwnerSettingShowUser.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/vip",
    name: "ChannelOwnerSettingVip",
    component: () => import("../views/panel/channel/ChannelOwnerSettingVip.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/account",
    name: "ChannelOwnerSettingAccountNew",
    component: () => import("../views/panel/channel/ChannelOwnerSettingAccountNew.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/edit-account/:accountId(\\d+)",
    name: "ChannelOwnerSettingAccountEdit",
    component: () => import("../views/panel/channel/ChannelOwnerSettingAccountEdit.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/income",
    name: "ChannelOwnerSettingIncome",
    component: () => import("../views/panel/channel/ChannelOwnerSettingIncome.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/income",
    name: "ChannelOwnerSettingIncomeAccount",
    component: () =>
      import("../views/panel/channel/ChannelOwnerSettingIncomeAccount.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-owner/:channelId/income-to",
    name: "ChannelOwnerSettingIncomeTo",
    component: () => import("../views/panel/channel/ChannelOwnerSettingIncomeTo.vue"),
    meta: {
      rootName: "channel",
    },
  },

  /**
   * ANCHOR -  channel user
   */
  {
    path: "/panel/:id(\\d+)/channel-user/",
    name: "ChannelUser",
    component: () => import("../views/panel/channel/ChannelUser.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-user/:channelId",
    name: "ChannelUserInfo",
    component: () => import("../views/panel/channel/ChannelUserInfo.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-user/:channelId/accounts",
    name: "ChannelUserAccounts",
    component: () => import("../views/panel/channel/ChannelUserAccounts.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-user/:channelId/accounts/:payId(\\d+)",
    name: "ChannelUserAccountsCheck",
    component: () => import("../views/panel/channel/ChannelUserAccountsCheck.vue"),
    meta: {
      rootName: "channel",
    },
  },
  {
    path: "/panel/:id(\\d+)/channel-user/:channelId/accounts/:payId(\\d+)/pay-result",
    name: "ChannelUserAccountsPayRes",
    component: () => import("../views/panel/channel/ChannelUserAccountsPayRes.vue"),
    meta: {
      rootName: "channel",
    },
  },

  /**
   * ANCHOR -  other routes
   */
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/login-by-token/:loginToken",
    name: "LoginByToken",
    component: () => import("../views/LoginByToken.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
  },

  /**
   * ANCHOR - admin routes
   */
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/admin/Index.vue"),
    meta: {
      admin: true,
    },
  },
  {
    path: "/admin/bots",
    name: "AdminBots",
    component: () => import("../views/admin/Bots.vue"),
    meta: {
      admin: true,
    },
  },
  {
    path: "/admin/bot/:botId",
    name: "AdminBot",
    component: () => import("../views/admin/Bot.vue"),
    meta: {
      admin: true,
    },
    meta: {
      rootName: "adminBot",
    },
  },
  {
    path: "/admin/users",
    name: "AdminUsers",
    component: () => import("../views/admin/Users.vue"),
    meta: {
      admin: true,
    },
  },
  {
    path: "/admin/ads",
    name: "AdminAds",
    component: () => import("../views/admin/Ads.vue"),
    meta: {
      admin: true,
    },
  },
  {
    path: "/admin/ads/day/:day",
    name: "AdminAdsDay",
    component: () => import("../views/admin/AdsDay.vue"),
    meta: {
      admin: true,
    },
  },
  {
    path: "/admin/groups",
    name: "AdminGroups",
    component: () => import("../views/admin/Groups.vue"),
    meta: {
      admin: true,
    },
  },
  {
    path: "/admin/channels",
    name: "AdminChannels",
    component: () => import("../views/admin/Channels.vue"),
    meta: {
      admin: true,
    },
  },
  {
    path: "/admin/income",
    name: "AdminIncome",
    component: () => import("../views/admin/Income.vue"),
    meta: {
      admin: true,
    },
  },
  {
    path: "/admin/payments",
    name: "AdminPayments",
    component: () => import("../views/admin/Payments.vue"),
    meta: {
      admin: true,
    },
  },
  {
    path: "/:notFound*",
    name: "404",
    component: Home,
    meta: {
      error: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("closeMenu");
  store.commit("setStatus", 200);
  // ...
  // explicitly return false to cancel the navigation
  // console.log("✅ before router:", from, to);
  if (to.params.id != store.state.user.id && to.params.id) {
    axios
      .get("/api/getMe", {
        params: {
          id: to.params.id,
        },
      })
      .then(function () {})
      .catch(function () {});
  }
  // let path = to.path.split("/");
  // if (store.state.firstCreate == true) {
  //   if ((store.state.user.is_admin ?? false) == false && path[1] == "admin") {
  //     next(false);
  //     store.commit("setStatus", 404);
  //   }
  // }
  next();
});
router.afterEach((to, from) => {
  // ...
  // explicitly return false to cancel the navigation
  // console.log("✅ after router:", from, to);
  // let path = to.path.split("/");
  // if (from.path == "/" && from.name == undefined) {
  //   if (store.state.user !== false && to.name == "Login") router.push("/panel");
  //   if (store.state.user === false && path[1] == "panel") router.push("/login");
  //   if (store.state.user?.admin === undefined && path[1] == "admin")
  //     store.commit("setStatus", 404);
  // }

  setTimeout(() => {
    var container = document.getElementById("mainContainer");
    var header = document.getElementById("mainHeader");
    if (to.hash) {
      var scrollTo = document.getElementById(to.hash.slice(1));
      if (scrollTo) {
        container.scrollTop = scrollTo.offsetTop + header.offsetHeight;
      }
    } else if (from.hash) {
      container.scrollTop = 0;
    } else if (container && from.path != to.path) {
      container.scrollTop = 0;
    }
  }, 0);
});

export default router;
