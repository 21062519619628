import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import Notifications from "@kyvg/vue3-notification";

import "@/assets/app.scss";
import "@/index.css";
import "@/assets/mnr-input.scss";

const app = createApp(App);

// axios.defaults.withCredentials = true;
axios.defaults.headers["Accept"] = "application/json";
axios.defaults.headers["Content-Type"] = "application/json";
if (localStorage.getItem("token")) {
  axios.defaults.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
}
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    let path = router.currentRoute._value.path.split("/");
    // console.log("✅ from axios response:", response);
    // if (response?.data?.token) {
    //   axios.defaults.headers["Authorization"] = "Bearer " + response.data.token;
    // }
    if (response?.data?.auth && response?.config?.url == "/api/getMe") {
      store.commit("userLogged", response.data.auth);
    }
    if (response.data.auth != false && path[1] == "login") router.push("/panel");
    if ((store.state.user?.admin?.is_admin ?? false) == false && path[1] == "admin")
      store.commit("setStatus", 404);
    else store.commit("setStatus", response.status);
    // store.commit("setStatus", response.status);
    return response;
  },
  (error) => {
    // whatever you want to do with the error
    // console.log("✅ from axios error:", error.response);
    store.commit("lastError", error.response);
    if (error?.data?.auth) {
      store.commit("userLogged", error.data.auth);
    }
    store.commit("setStatus", error.response.status);
    return Promise.reject(error.response);
  }
);

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // console.log("✅ from axios config:", config);
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

var number = new Intl.NumberFormat();

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$number = number;
app.use(store).use(router).use(Notifications).mount("#app");
